<template>
  <div style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-row>
                <v-col class='d-flex flex-row align-center'>
                  <h1>Database Utility</h1>
                  <v-progress-circular
                      indeterminate
                      color="green"
                      v-if="loader"
                      style="margin-left: 10px;"
                  ></v-progress-circular>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Creating Demo Data</h2>
                  <v-row class="mb-2">
                    <v-col>
                      <v-btn @click="createUsers">Create Users (10)</v-btn>
                      <v-btn @click="createCustomers">Create Customers (10)</v-btn>
                      <v-btn @click="createSuppliers">Create Suppliers (10)</v-btn>
                      <v-btn @click="createProducts">Create Products (50)</v-btn>
                      <v-btn @click="stockProducts">Stock Products</v-btn>
                    </v-col>
                  </v-row>
                  <h2>Writing/Backing Up Data</h2>
                  <v-row class="mb-2">
                    <v-col>
                      <v-btn @click="saveUsers">Save Users (JSON)</v-btn>
                      <v-btn @click="saveSuppliers">Save Suppliers (JSON)</v-btn>
                      <v-btn @click="saveProducts">Save Products (JSON)</v-btn>
                    </v-col>
                  </v-row>
                  <h2>Reading New Data</h2>
                  <v-row class="mb-2">
                    <v-col>
                      <v-btn @click="readUsers">Read Users (JSON)</v-btn>
                      <v-btn @click="readSuppliers">Read Suppliers (JSON)</v-btn>
                      <v-btn @click="readProducts">Read Products (JSON)</v-btn>
                      <v-btn @click="readAllData">Read All Data (JSON)</v-btn>
                    </v-col>
                  </v-row>
                  <h2>Data Manipulation</h2>
                  <br>
                  <v-row class="mb-2">
                    <v-col class="d-flex flex-row">
                      <v-autocomplete clearable multiple chips deletable-chips outlined label="Table Name" :items="tableNames" v-model="dataManipTableName"/>
                      <v-btn @click="resetTable" color="error" class="ml-2" :disabled="!dataManipTableName">Reset Tables</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex flex-row">
                      <span class="d-flex flex-column px-2">
                        <v-btn @click="resetVariationNames">Reset Variation Names</v-btn>
                      </span>
                      <span class="d-flex flex-column px-2">
                        <v-btn @click="convertPSJData">Convert PSJ Data</v-btn>
                        <span class="red--text" style="font-size: 12px;">Legacy PSJ Product Data is converted to new format.</span>
                      </span>
                      <span class="d-flex flex-column px-2">
                        <v-btn @click="convertLegacyProductData">Convert Legacy Product Data</v-btn>
                        <span class="red--text" style="font-size: 12px;">Legacy Product Data is converted to new format.</span>
                      </span>
                      <span class="d-flex flex-column px-2">
                        <v-btn @click="resetCategoryFamilies">Reset Category Families</v-btn>
                        <span class="red--text" style="font-size: 12px;">All categories' family slugs and names will be regenerated.</span>
                      </span>
                      <span class="d-flex flex-column px-2">
                        <v-btn @click="resetProducts">Reset Everything Products</v-btn>
                        <span class="red--text" style="font-size: 12px;">{{["Brands", "ProductAttributeJoins", "ProductAttributeTermJoins", "ProductAttributeTerms", "ProductAttributes", "ProductCategories", "ProductCategoriesJoins", "ProductLocationJoins", "ProductSupplierJoins", "ProductTags", "ProductTagsJoins", "Products", "Suppliers"].join(", ")}} will be deleted.</span>
                      </span>
                      <span class="d-flex flex-column px-2">
                        <v-btn @click="overwriteBranchOfAllRecords" :disabled="!branchId">Overwrite Branch of All Records</v-btn>
                        <span class="red--text" style="font-size: 12px;">Branch IDs for {{["Orders", "Receiving Report", "Returns", "Purchase Orders", "Promotions"].join(", ")}} will be changed.</span>
                        <span class="d-flex flex-row">
                          <v-text-field v-model="replacementId" label="ID to Replace" type="number" dense outlined clearable/>
                          <v-text-field v-model="branchId" label="ID to Insert" type="number" dense outlined clearable/>
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="d-flex flex-column px-2">
                        <v-btn @click="cleanLocationRelatedData">Delete All PLJs for Deleted Locations</v-btn>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h1>Product Data</h1>
                      <v-row>
                        <v-col class="">
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="setProductsManaged">Set Products Managed</v-btn>
                          </span>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="setProductsUnmanaged">Set Products Unmanaged</v-btn>
                          </span>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="validateSKUs">Validate SKUs</v-btn>
                          </span>
                            <br>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="setNoneProductsUnmanaged">Set 0 or None QTY Products to Unmanaged</v-btn>
                          </span>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="setNoneProductsManaged">Set 0 or None QTY Products to Managed</v-btn>
                          </span>
                        </v-col>
                        <v-col class="">
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="setProductsTempQuantityAllLocations">SET ALL TEMP QUANTITY</v-btn>
                          </span>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="unsetProductsTempQuantityAllLocations">UNSET ALL TEMP QUANTITY</v-btn>
                          </span>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="convertUnmanagedToTempQuantity">Convert Unmanaged Products to Temp Quantities</v-btn>
                          </span>
                          <br>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="fixQuotesOnProductNames">Convert Random Quotes to Proper in Product Name</v-btn>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h1>Order Data</h1>
                      <v-row>
                        <v-col>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="createVatValuesOnLineItems">Create VAT Values on Line Items</v-btn>
                          </span>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="updateOLILineTotals">Update OLI LineTotals</v-btn>
                          </span>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="fixAllOrdersWithNullGrandTotal">Fix All Orders With Null GrandTotal</v-btn>
                          </span>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="correctOrdersSealedById">Correct Sealed By Ids</v-btn>
                          </span>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="fixDuplicateInvoiceTransactions">Remove Duplicate Invoice Transactions</v-btn>
                          </span>
                        </v-col>
                        <v-col></v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h1>User Data</h1>
                      <v-row>
                        <v-col class="">
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="setUsersTimeLockAllow">SET ALL USERS TIMELOCK ALLOW</v-btn>
                            <span class="red--text" style="font-size: 12px;">This will set all users except you.</span>
                          </span>
                          <span class="d-flex flex-column px-2 mb-1">
                            <v-btn @click="setUsersTimeLockBlock">SET ALL USERS TIMELOCK BLOCK</v-btn>
                            <span class="red--text" style="font-size: 12px;">This will set all users except you.</span>
                          </span>
                        </v-col>
                        <v-col></v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <h1 class="mt-5">Import CSVs and Configurations</h1>
          <v-row class="mb-2 align-center">
            <v-col>
              <v-row>
                <v-col>
                  <h2>Custom Imports</h2>
                  <v-file-input label="Import File" @change="onAddFiles"></v-file-input>
                  <v-progress-circular
                      v-if="productsFileLoad"
                      indeterminate
                      color="primary"
                  />
                  <span class="d-flex flex-row">
                <v-btn @click="csvImport">CSV Upload</v-btn>
              </span>
                </v-col>
              </v-row>
              <hr>
              <v-row>
                <v-col>
                  <h3>Woocommerce CSV Upload</h3>
                  <v-file-input label="Import File" @change="onAddWooFiles"></v-file-input>
                  <v-progress-circular
                      v-if="wooProductsFileLoad"
                      indeterminate
                      color="primary"
                  />
                  <span class="d-flex flex-row">
                <v-btn @click="wooCsvImport">CSV Woocommerce Upload</v-btn>
              </span>
                </v-col>
              </v-row>
              <hr>
              <v-row>
                <v-col>
                  <h3>Vend Basic CSV Upload</h3>
                  <v-file-input label="Import File" @change="onAddVendFiles"></v-file-input>
                  <v-progress-circular
                      v-if="vendProductsFileLoad"
                      indeterminate
                      color="primary"
                  />
                  <span class="d-flex flex-row">
                <v-btn @click="vendCsvImport">CSV Vend Upload</v-btn>
              </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-card>
                <v-card-title>CSV Parsing Options</v-card-title>
                <v-card-text class="d-flex flex-column">
                  <span class="red--text">Categories and Tags can only be processed from the allowed Product Types.</span>
                  <span class="d-flex flex-row flex-wrap">
                    <v-checkbox class="mr-3" v-for="key of Object.keys(csvOptions)" :label="key" v-model="csvOptions[key]" :key="key"/>
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <h2>Data Import Parser (Outdated)</h2>
                  <v-text-field label="Table Name" v-model="tableName" type="text"/>
                  <v-file-input label="Table Data File" @change="onFileImport"></v-file-input>
                  <v-progress-circular
                      v-if="fileLoading"
                      indeterminate
                      color="primary"
                  ></v-progress-circular>
                  <v-btn v-else @click="uploadImportFire">Products CSV Upload</v-btn>
                </v-col>
              </v-row>
              <br>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <div>
      <h2>Data Reports</h2>
      <div class="d-flex flex-column col-3">
        <h3>Stock By Search Term</h3>
        <v-text-field
            label="Search Term"
            v-model="productStockReport.term"
            outlined
        />
        <v-select
            label="Part of Field"
            :items="fieldParts"
            v-model="productStockReport.searchPart"
            outlined
        />
        <v-select
            label="Product Field"
            :items="productFields"
            v-model="productStockReport.field"
            outlined
        />
        <v-btn @click="getStockBySearchTerm" :disabled="!productStockReport.term">Get Data By Search Term</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters, mapMutations} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,

        productsFileLoad: false,
        file: '',

        wooProductsFileLoad: false,
        woofile: '',

        vendProductsFileLoad: false,
        vendfile: '',

        csvOptions: {
          processCategories: true,
          processTags: true,
          processSimple: true,
          processVariable: true,
          processQuantities: true,
          processSuppliers: true
        },

        tableNames: [],
        dataManipTableName: [],

        branchId: "",
        replacementId: "",

        fileLoading: false,
        importFile: '',
        tableName: "",

        fieldParts: ["starts", "contains", "ends", "exact"],
        productFields: ["name", "sku"],

        productStockReport: {
          term: "",
          searchPart: "contains",
          field: "name"
        },
      }
    },
    async mounted(){
      let res = await axios.get(`${this.getEndpoint}/api/sync/getTables`)
      if(res.data.error) throw res.data.error;
      this.tableNames = res.data.data;
    },
    computed: {
      ...mapGetters(['getEndpoint'])
    },
    methods: {
      ...mapMutations(["setSnack"]),
      async productsSyncDownload(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/productsdownload`)
          console.log(res);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async customersSyncDownload(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/customersdownload`)
          console.log(res);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async uploadProductsCSV(){
        try{
          this.productsFileLoad = true;
          if(!this.file) throw "There are no files";

          let formData = new FormData();
          formData.append("file", this.file);
          formData.append("name", this.file.name);

          let fileUpload = await axios.post(`${this.getEndpoint}/api/sync/productscsvupload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          console.log(fileUpload);
        }
        catch(e) {
          console.error(e);
        }
        finally {
          this.productsFileLoad = false;
        }
      },
      onAddFiles(file){
        this.file = file;
      },
      onAddWooFiles(file){
        this.woofile = file;
      },
      onAddVendFiles(file){
        this.vendfile = file;
      },
      async productQuantitiesInit(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/productQuantitiesInit`)
          console.log(res);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async productQuantitiesGen(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/productQuantitiesGen`)
          console.log(res.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async generateUsernames(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/generateusernames`)
          console.log(res.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async generateProductTypes(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/productsTypeGen`)
          console.log(res.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async uploadImportFire(){
        try{
          this.fileLoading = true;
          if(!this.importFile) throw "There are no files";

          let formData = new FormData();
          formData.append("file", this.importFile);
          formData.append("name", this.importFile.name);

          let data = await axios.post(`${this.getEndpoint}/api/sync/csvimport?table=${this.tableName}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          console.log(data);
        }
        catch(e) {
          console.error(e);
        }
        finally {
          this.fileLoading = false;
        }
      },
      onFileImport(file){
        this.importFile = file;
      },
      async createUsers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/createUsers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async createProducts(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/createProducts`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async createCustomers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/createCustomers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async createSuppliers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/createSuppliers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async stockProducts(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/stockProducts`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async saveProducts(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/saveProducts`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async saveUsers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/saveUsers`, { responseType: 'blob' });

          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', "users.json");
          fileLink.setAttribute('target', '_blank');
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();

          if(res.data.error) throw res.data.error
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async saveSuppliers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/saveSuppliers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async readProducts(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/readProducts`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async readUsers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/readUsers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async readSuppliers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/readSuppliers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async backupData(){
        try {
          this.loader = true;
          let Users = await axios.get(`${this.getEndpoint}/api/sync/saveUsers`)
          console.log("Users", Users.data);

          let Suppliers = await axios.get(`${this.getEndpoint}/api/sync/saveSuppliers`)
          console.log("Suppliers", Suppliers.data);

          let Deliveries = await axios.get(`${this.getEndpoint}/api/sync/saveDeliveries`)
          console.log("Deliveries", Deliveries.data);

          let Customers = await axios.get(`${this.getEndpoint}/api/sync/saveCustomers`)
          console.log("Customers", Customers.data);

          let Orders = await axios.get(`${this.getEndpoint}/api/sync/saveOrders`)
          console.log("Orders", Orders.data);

          let Devices = await axios.get(`${this.getEndpoint}/api/sync/saveDevices`)
          console.log("Devices", Devices.data);

          let Products = await axios.get(`${this.getEndpoint}/api/sync/saveProducts`)
          console.log("Products", Products.data);

          let PurchaseOrders = await axios.get(`${this.getEndpoint}/api/sync/savePurchaseOrders`)
          console.log("PurchaseOrders", PurchaseOrders.data);

          let ReceivingReports = await axios.get(`${this.getEndpoint}/api/sync/saveReceivingReports`)
          console.log("ReceivingReports", ReceivingReports.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async readAllData(){
        try {
          this.loader = true;
          let Users = await axios.get(`${this.getEndpoint}/api/sync/readUsers`)
          console.log("Users", Users.data);

          let Customers = await axios.get(`${this.getEndpoint}/api/sync/readCustomers`)
          console.log("Customers", Customers.data);

          let Suppliers = await axios.get(`${this.getEndpoint}/api/sync/readSuppliers`)
          console.log("Suppliers", Suppliers.data);

          let Products = await axios.get(`${this.getEndpoint}/api/sync/readProducts`)
          console.log("Products", Products.data);

          let PurchaseOrders = await axios.get(`${this.getEndpoint}/api/sync/readPurchaseOrders`)
          console.log("PurchaseOrders", PurchaseOrders.data);

          let ReceivingReports = await axios.get(`${this.getEndpoint}/api/sync/readReceivingReports`)
          console.log("ReceivingReports", ReceivingReports.data);

          let Orders = await axios.get(`${this.getEndpoint}/api/sync/readOrders`)
          console.log("Orders", Orders.data);

          let Deliveries = await axios.get(`${this.getEndpoint}/api/sync/readDeliveries`)
          console.log("Deliveries", Deliveries.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async csvImport(){
        try{
          this.productsFileLoad = true;
          if(!this.file) throw "There are no files";

          let formData = new FormData();
          formData.append("file", this.file);
          formData.append("name", this.file.name);

          let uriFields = Object.keys(this.csvOptions).map(x => {
            return x + "=" + (this.csvOptions[x]!==undefined?encodeURIComponent(this.csvOptions[x]):'')
          }).join("&");

          let fileUpload = await axios.post(`${this.getEndpoint}/api/sync/varioncsvupload?${uriFields}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          console.log(fileUpload);
        }
        catch(e) {
          console.error(e);
        }
        finally {
          this.productsFileLoad = false;
        }
      },
      async wooCsvImport(){
        try{
          this.wooProductsFileLoad = true;
          if(!this.woofile) throw "There are no files";

          let formData = new FormData();
          formData.append("file", this.woofile);
          formData.append("name", this.woofile.name);

          let uriFields = Object.keys(this.csvOptions).map(x => {
            return x + "=" + (this.csvOptions[x]!==undefined?encodeURIComponent(this.csvOptions[x]):'')
          }).join("&");

          let fileUpload = await axios.post(`${this.getEndpoint}/api/sync/woocommercecsvupload?${uriFields}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          console.log(fileUpload);
        }
        catch(e) {
          console.error(e);
        }
        finally {
          this.wooProductsFileLoad = false;
        }
      },
      async vendCsvImport(){
        try{
          this.vendProductsFileLoad = true;
          if(!this.vendfile) throw "There are no files";

          let formData = new FormData();
          formData.append("file", this.vendfile);
          formData.append("name", this.vendfile.name);

          let uriFields = Object.keys(this.csvOptions).map(x => {
            return x + "=" + (this.csvOptions[x]!==undefined?encodeURIComponent(this.csvOptions[x]):'')
          }).join("&");

          let fileUpload = await axios.post(`${this.getEndpoint}/api/sync/vendcsvupload?${uriFields}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          console.log(fileUpload);
        }
        catch(e) {
          console.error(e);
        }
        finally {
          this.vendProductsFileLoad = false;
        }
      },
      async resetTable(){
        try{
          let confirm = window.confirm(`Are you sure you want to reset "${this.dataManipTableName.join(", ")}"`);

          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/resetTable`, {tables: this.dataManipTableName})
          if(res.data.error) throw res.data.error;

          console.log(res.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async resetProducts(){
        try{
          this.dataManipTableName = ["Brands", "ProductAttributeJoins", "ProductAttributeTermJoins", "ProductAttributeTerms", "ProductAttributes", "ProductCategories", "ProductCategoriesJoins", "ProductLocationJoins", "ProductSupplierJoins", "ProductTags", "ProductTagsJoins", "Products", "Suppliers"]


          let confirm = window.confirm(`Are you sure you want to reset "${this.dataManipTableName.join(", ")}"`);
          if(!confirm){
            this.dataManipTableName = [];
            return;
          }


          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/resetTable`, {tables: this.dataManipTableName})
          if(res.data.error) throw res.data.error;

          console.log(res.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.dataManipTableName = [];
          this.loader = false;
        }
      },
      async resetVariationNames(){
        try{
          let confirm = window.confirm(`Are you sure you want to reset variation names?"`);

          if(!confirm) return;

          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/convertVariationNames`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async resetCategoryFamilies(){
        try{
          let confirm = window.confirm(`Are you sure you want to reset category families?"`);

          if(!confirm) return;

          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/generateCategoryFamilies`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async convertPSJData(){
        try{
          let confirm = window.confirm(`Are you sure you want to convert PSJ Data?"`);

          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/convertPSJData`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async overwriteBranchOfAllRecords(){
        try{
          let confirm = window.confirm(`Are you sure you want to overwrite branch ID of all records (Orders, POs, RRs, Returns, Promotions) only where matching replacement or null?"`);

          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/overwriteBranchOfAllRecords`, {branchId: this.branchId, replacementId: this.replacementId})
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async convertLegacyProductData(){
        try{
          let confirm = window.confirm(`Are you sure you want to convert Product Data?"`);

          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/convertLegacyProductData`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async setProductsManaged(){
        try{
          let confirm = window.confirm(`Are you sure you want to set all products to Managed Stock?"`);

          if(!confirm) return;

          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/setProductsManaged`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async setProductsUnmanaged(){
        try{
          let confirm = window.confirm(`Are you sure you want to set all products to Unmanaged Stock?"`);

          if(!confirm) return;

          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/setProductsUnmanaged`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async validateSKUs(){
        try{
          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/validateSKUs`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async setNoneProductsUnmanaged(){
        try{
          let confirm = window.confirm(`Are you sure you want to set all 0 or none QTY products to Unmanaged Stock?"`);

          if(!confirm) return;

          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/setNoneProductsUnmanaged`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async setNoneProductsManaged(){
        try{
          let confirm = window.confirm(`Are you sure you want to set all 0 or none QTY products to Managed Stock?"`);

          if(!confirm) return;

          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/setNoneProductsManaged`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async convertUnmanagedToTempQuantity(){
        try{
          let confirm = window.confirm(`Are you sure you want to set all unmanaged products to the TEMP QUANTITY?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/convertUnmanagedToTempQuantity`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e)
        }
        finally {
          this.loader = false;
        }
      },
      async setProductsTempQuantityAllLocations(){
        try{
          let confirm = window.confirm(`Are you sure you want to SET all products to the TEMP QUANTITY?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/setProductsTempQuantityAllLocations`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async unsetProductsTempQuantityAllLocations(){
        try{
          let confirm = window.confirm(`Are you sure you want to UNSET all products to the TEMP QUANTITY?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/unsetProductsTempQuantityAllLocations`)
          if(res.data.error) throw res.data.error;
          console.log(res.data.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async correctOrdersSealedById(){
        try{
          let confirm = window.confirm(`Are you sure you want to copy the createdBy Id as sealedBy Id for all Orders with null sealedBy?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/correctOrdersSealedById`)
          if(res.data.error) throw res.data.error;

          console.log(res.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async fixAllOrdersWithNullGrandTotal(){
        try{
          let confirm = window.confirm(`Are you sure you want to regenerate the grand total for all Orders with null grand total?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/fixAllOrdersWithNullGrandTotal`)
          if(res.data.error) throw res.data.error;

          console.log(res.data.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async fixQuotesOnProductNames(){
        try{
          let confirm = window.confirm(`Are you sure you want to change to random quotations to specific?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/fixQuotesOnProductNames`)
          if(res.data.error) throw res.data.error;

          console.log(res.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async fixDuplicateInvoiceTransactions(){
        try{
          let confirm = window.confirm(`Are you sure you want to remove duplicate invoice transactions?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/sync/fixDuplicateInvoiceTransactions`)
          if(res.data.error) throw res.data.error;

          console.log(res.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async getStockBySearchTerm(){
        try{
          this.loader = true;

          let obj = this.productStockReport;

          let uriFields = Object.keys(obj).map(x => {
            return x + "=" + (obj[x]!==undefined?encodeURIComponent(obj[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/sync/getStockBySearchTerm?${uriFields}`)
          if(res.data.error) throw res.data.error

          console.log(res.data);
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async createVatValuesOnLineItems(){
        try{
          let confirm = window.confirm(`Are you sure you want to force create and overwrite all VAT data on Order Line Items?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/createVatValuesOnLineItems`)
          if(res.data.error) throw res.data.error;

          console.log(res.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async updateOLILineTotals(){
        try{
          let confirm = window.confirm(`Are you sure you want to force create and overwrite all Order Line Item Totals?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/updateOLILineTotals`)
          if(res.data.error) throw res.data.error;

          console.log(res.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async setUsersTimeLockAllow(){
        try{
          let confirm = window.confirm(`Are you sure you want to overwrite all User Timelock Data?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/setUsersTimeLock/1`)
          if(res.data.error) throw res.data.error;

          console.log(res.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async setUsersTimeLockBlock(){
        try{
          let confirm = window.confirm(`Are you sure you want to overwrite all User Timelock Data?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/setUsersTimeLock/0`)
          if(res.data.error) throw res.data.error;

          console.log(res.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
      async cleanLocationRelatedData(){
        try{
          let confirm = window.confirm(`Are you sure you want to delete all old unused location data?"`);
          if(!confirm) return;

          this.loader = true;

          let res = await axios.post(`${this.getEndpoint}/api/sync/cleanLocationRelatedData`)
          if(res.data.error) throw res.data.error;

          console.log(res.data)
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loader = false;
        }
      },
    }
  }
</script>
